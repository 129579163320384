import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import PhoneIcon from "@mui/icons-material/Phone";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const SydneyWrite = () => {
  const state = useLocation().state;

  const [title, setTitle] = useState(state?.title || "");
  const [value, setValue] = useState(state?.desc || "");
  const [files, setFiles] = useState([]); // เปลี่ยนชื่อ state จาก file เป็น files เพื่อความชัดเจน
  const [phone, setPhone] = useState(state?.phone || "");
  const [lineid, setLineid] = useState(state?.lineid || "");
  const [view, setView] = useState(state?.view || "1");
  const [cat, setCat] = useState(state?.cat || "");
  const [count, setCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]); // state สำหรับไฟล์ที่อัปโหลดแล้ว

  const navigate = useNavigate();

  const validateFile = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
      "image/bmp",
    ];
    if (!allowedTypes.includes(file.type)) {
      Swal.fire({
        title: "Error!",
        text: "ไฟล์ที่เลือกไม่ถูกต้อง กรุณาเลือกไฟล์รูปภาพที่มีนามสกุล .jpg, .png, .gif, หรือ .bmp",
        icon: "error",
      });
      return false;
    }
    if (file.size > 3 * 1024 * 1024) {
      Swal.fire({
        title: "Error!",
        text: "ขนาดไฟล์เกิน 3MB กรุณาเลือกไฟล์ที่มีขนาดเล็กกว่า 3MB",
        icon: "error",
      });
      return false;
    }
    return true;
  };

  const upload = async () => {
    if (!files || files.length === 0) return []; // ตรวจสอบว่ามีไฟล์

    const formData = new FormData();
    
    // เพิ่มไฟล์ทั้งหมดไปยัง FormData
    files.forEach((f) => {
      if (validateFile(f)) formData.append("files", f); // ตรวจสอบไฟล์และเพิ่มไฟล์ที่ผ่านเงื่อนไข
    });
  
    try {
      const res = await axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadedFiles(res.data); // อัปเดต state ด้วยชื่อไฟล์ที่อัปโหลดแล้ว
      return res.data; // ส่งคืนชื่อไฟล์ที่อัปโหลด
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Error!",
        text: "การอัปโหลดไฟล์ล้มเหลว",
        icon: "error",
      });
      return [];
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
  
    if (!title || !cat) {
      Swal.fire({
        text: "กรุณาเลือกประเภทโพสต์และใส่ข้อความหัวข้อเรื่อง",
        icon: "warning",
      });
      return;
    }
  
    const imgUrlArray = await upload(); // ตรวจสอบว่าคืนชื่อไฟล์กลับมาถูกต้อง
    if (!imgUrlArray.length) {
      console.error("Image upload failed, imgUrl is invalid.");
    }
  
    const postData = {
      title,
      desc: value,
      img: imgUrlArray.join(","), // แปลง array เป็น string เพื่อเก็บใน database
      phone,
      lineid,
      view,
      date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      cat,
    };
  
    // แสดงกล่องยืนยันก่อนโพสต์
    Swal.fire({
      title: "ยืนยันการสร้างโพสต์?",
      text: "คุณแน่ใจว่าต้องการสร้างโพสต์นี้หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = state
            ? await axios.put(`https://api.austhaitown.com/api/darwin/${state.id}`, postData)
            : await axios.post(`https://api.austhaitown.com/api/darwin`, postData);
  
          Swal.fire({
            title: "สำเร็จ!",
            text: "สร้างโพสต์เรียบร้อยแล้ว",
            icon: "success",
            timer: 3000,
          });
  
          navigate("/darwin");
        } catch (err) {
          console.error("Error updating post:", err);
          Swal.fire({
            title: "Error!",
            text: "สร้างโพสต์ไม่สำเร็จ อาจมีข้อความที่ยาวเกินไป หรือมีรูปภาพขนาดเกิน 3MB",
            icon: "error",
          });
        }
      }
    });
  };
  

  const option = [
    { label: "เลือกประเภทหัวข้อ", value: "" },
    { label: "ห้องว่าง", value: "ห้องว่าง" },
    { label: "หาบ้าน", value: "หาบ้าน" },
    { label: "รับสมัครงาน", value: "รับสมัครงาน" },
    { label: "หางาน", value: "หางาน" },
    { label: "รับทำการบ้าน", value: "รับทำการบ้าน" },
    { label: "ซื้อ", value: "ซื้อ" },
    { label: "ขาย", value: "ขาย" },
    { label: "รับขนของ", value: "รับขนของ" },
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  return (
    <div id="-sydney-write">
      <div className="content">
        <div className="-item">
          <button className="btn-back" onClick={() => navigate(-1)}>ย้อนกลับ</button>
          <h4>เลือกประเภทโพสต์</h4>
          <div className="cat">
            <select className="listmenu" defaultValue={cat} onChange={(e) => setCat(e.target.value)}>
              {option.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <h4>หัวข้อเรื่อง {count}/300</h4>
        <input
          type="text"
          placeholder="สูงสุด 300 ตัวอักษร"
          value={title}
          maxLength="300"
          onChange={(e) => {
            setTitle(e.target.value);
            setCount(e.target.value.length);
          }}
        />
        <div className="editor-container">
          <h4>เนื้อหา {value.length}/3000</h4>
          <ReactQuill
            className="editor"
            placeholder="ข้อความไม่เกิน 3000 ตัวอักษร"
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
        <div className="contacts">
          <PhoneIcon />
          <input
            type="text"
            placeholder="เบอร์โทรศัพท์"
            maxLength="10"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <QrCode2Icon />
          <input
            type="text"
            placeholder="Line ID"
            value={lineid}
            onChange={(e) => setLineid(e.target.value)}
          />
        </div>
      </div>

      <div className="menu">
        <div className="item">
          <h4>อัพโหลดรูปภาพ</h4>
          <input
            className="btn-img"
            accept="image/*"
            type="file"
            multiple
            onChange={(e) => {
              const selectedFiles = Array.from(e.target.files);
              setFiles(selectedFiles);
            }}
          />
          <p>อนุญาตให้อัพโหลดเฉพาะไฟล์: .jpg, .gif, .jpeg, .png, .bmp (Max file size: 3MB)</p>
        </div>
      </div>

      {uploadedFiles.length > 0 && (
  <div className="uploaded-images">
    <h4>รูปภาพที่อัปโหลดแล้ว:</h4>
    <div className="image-list">
      {uploadedFiles.map((file, index) => (
        <img
          key={index}
          src={`/upload/${file}`} // URL สำหรับแสดงรูปภาพที่อัปโหลด
          alt={file.name}
          style={{ width: "100px", margin: "10px" }}
        />
      ))}
    </div>
  </div>
)}

      <div className="btn-submit">
        <button onClick={handleClick}>สร้างโพสต์</button>
      </div>
    </div>
  );
};

export default SydneyWrite;

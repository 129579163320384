import React, { useEffect, useState } from "react";
import sydney from "../images/sydney.jpeg";
import melbourne from "../images/melbourne.webp";
import brisbane from "../images/brisbane.webp";
import perth from "../images/perth.jpg";
import adelaide from "../images/adelaide.webp";
import darwin from "../images/darwin.jpg";
import hobart from "../images/hobart.webp";
import { Link, useLocation } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import axios from "axios";

const cities = [
  { name: "sydney", label: "Sydney, New South Wales", img: sydney },
  { name: "melbourne", label: "Melbourne, Victoria", img: melbourne },
  { name: "brisbane", label: "Brisbane, Queensland", img: brisbane },
  { name: "perth", label: "Perth, Western Australia", img: perth },
  { name: "adelaide", label: "Adelaide, South Australia", img: adelaide },
  { name: "darwin", label: "Darwin, Northern Territory", img: darwin },
  { name: "hobart", label: "Hobart, Tasmania", img: hobart },
];

const Main = () => {
  const [posts, setPosts] = useState([]);
  const [totals, setTotals] = useState({}); // ใช้สถานะเพื่อเก็บจำนวนโพสต์ทั้งหมด
  const cat = useLocation().search;

  const fetchPosts = async (city) => {
    try {
      const res = await axios.get(`https://api.austhaitown.com/api/${city}${cat}`);
      setPosts(prevPosts => [...prevPosts, ...res.data]);
      setTotals(prevTotals => ({ ...prevTotals, [city]: res.data.length }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    cities.forEach(city => fetchPosts(city.name));
  }, [cat]);

  return (
    <>
      <div id="main-page">
        <div className="text-news">
          <InfoIcon />
          <marquee scrollamount="5" direction="left">
            AUSTHAITOWN คนไทยในออสเตรเลีย หาบ้าน หางาน หาเงิน หาโรงแรม ขายของ หาเพื่อน หาแฟน หาเอเจนต์ จ้างทำการบ้าน สมัครสมาชิกแล้วโพสต์ได้เลย
          </marquee>
        </div>
        <div className="city-town">
          {cities.map(city => (
            <div key={city.name} className="-city-town">
              <Link to={`/${city.name}`}>
                <img src={city.img} alt={city.label} />
                <p>{city.label}</p>
                <p>จำนวน {totals[city.name] || 0} โพสต์</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Main;

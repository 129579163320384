// Import the necessary components from the react-router-dom package and other custom components
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Footer from "./components/Footer";
import "./style.scss";
import Main from "./pages/Main";
import Iconbar from "./components/Iconbar";
import Sydney from "./pages/Sydney";
import SydneyWrite from "./pages/SydneyWrite";
import SydneySingle from "./pages/SydneySingle";
import Melbourne from "./pages/Melbourne";
import MelbourneWrite from "./pages/MelbourneWrite";
import MelbourneSingle from "./pages/MelbourneSingle";
import Brisbane from "./pages/Brisbane";
import BrisbaneWrite from "./pages/BrisbaneWrite";
import BrisbaneSingle from "./pages/BrisbaneSingle";
import Perth from "./pages/Perth";
import PerthWrite from "./pages/PerthWrite";
import PerthSingle from "./pages/PerthSingle";
import Adelaide from "./pages/Adelaide";
import AdelaideWrite from "./pages/AdelaideWrite";
import AdelaideSingle from "./pages/AdelaideSingle";
import Darwin from "./pages/Darwin";
import DarwinWrite from "./pages/DarwinWrite";
import DarwinSingle from "./pages/DarwinSingle";
import HobartSingle from "./pages/HobartSingle";
import HobartWrite from "./pages/HobartWrite";
import Hobart from "./pages/Hobart";
import Contactus from "./components/Contactus";
import Howto from "./components/Howto";
import Profile from "./components/Profile";


// Import authentication context
import { AuthContext } from "./context/authContext";
import { useContext } from "react";

// Create ProtectedRoute component to handle protected pages
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? children : <Navigate to="/login" />;
};

// Create a Layout component that defines the structure of the web page
const Layout = () => {
  return (
    <>
      <Iconbar />
      <Outlet />
      <Footer />
    </>
  );
};

// Define the application routes and components using the createBrowserRouter function
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/sydney/:id",
        element: <SydneySingle />,
      },
      {
        path: "/sydney/write",
        element: (
          <ProtectedRoute>
            <SydneyWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/sydney",
        element: <Sydney />,
      },
      {
        path: "/melbourne/:id",
        element: <MelbourneSingle />,
      },
      {
        path: "/melbourne/write",
        element: (
          <ProtectedRoute>
            <MelbourneWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/melbourne",
        element: <Melbourne />,
      },
      {
        path: "/brisbane/:id",
        element: <BrisbaneSingle />,
      },
      {
        path: "/brisbane/write",
        element: (
          <ProtectedRoute>
            <BrisbaneWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/brisbane",
        element: <Brisbane />,
      },
      {
        path: "/perth/:id",
        element: <PerthSingle />,
      },
      {
        path: "/perth/write",
        element: (
          <ProtectedRoute>
            <PerthWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/perth",
        element: <Perth />,
      },
      {
        path: "/adelaide/:id",
        element: <AdelaideSingle />,
      },
      {
        path: "/adelaide/write",
        element: (
          <ProtectedRoute>
            <AdelaideWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/adelaide",
        element: <Adelaide />,
      },
      {
        path: "/darwin/:id",
        element: <DarwinSingle />,
      },
      {
        path: "/darwin/write",
        element: (
          <ProtectedRoute>
            <DarwinWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/darwin",
        element: <Darwin />,
      },
      {
        path: "/hobart/:id",
        element: <HobartSingle />,
      },
      {
        path: "/hobart/write",
        element: (
          <ProtectedRoute>
            <HobartWrite />
          </ProtectedRoute>
        ),
      },
      {
        path: "/hobart",
        element: <Hobart />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/contactus",
        element: <Contactus />,
      },
      {
        path: "/howto",
        element: <Howto />,
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
    ],
  },
  // Route for handling 404 Not Found page
]);

// Define the App function that returns the RouterProvider component that provides the routing context to the entire app
function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

// Export the App component as the default export
export default App;

import React, { useContext, useEffect, useState } from "react";
import EditImage from "../images/edit.png";
import DeleteImage from "../images/delete.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import Swal from "sweetalert2";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ads from "../images/ads1.png";

const SydneySingle = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const postId = location.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.austhaitown.com/api/melbourne/${postId}`);
        setPost(response.data);
        await axios.put(`https://api.austhaitown.com/api/melbourne/view/${postId}`);
      } catch (error) {
        console.error('Error fetching post:', error);
        Swal.fire({
          title: "Error!",
          text: "ไม่สามารถดึงข้อมูลโพสต์ได้",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    const confirmed = await Swal.fire({
      title: "คุณแน่ใจหรือไม่?",
      text: "คุณจะไม่สามารถคืนค่าได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ใช่, ลบเลย!",
      cancelButtonText: "ไม่, ยกเลิก!",
      confirmButtonColor: "#d33", // สีปุ่มยืนยัน
      cancelButtonColor: "#3085d6" // สีปุ่มยกเลิก
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.delete(`https://api.austhaitown.com/api/melbourne/${postId}`);
        Swal.fire({
          title: "Deleted!",
          text: "Your post has been deleted.",
          icon: "success",
          timer: 3000,
        });
        navigate("/melbourne");
      } catch (err) {
        console.error("Error deleting post:", err);
      }
    }
  };

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setSelectedImageIndex(0);
  };

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setTouchStartX(touch.clientX);
  };

  const handleTouchMove = (event) => {
    if (touchStartX === null) return;

    const touch = event.touches[0];
    const touchEndX = touch.clientX;

    if (touchStartX > touchEndX + 50) {
      // Swipe left
      setSelectedImageIndex((prevIndex) => (prevIndex + 1) % post.img.split(',').length);
      setTouchStartX(null); // Reset touch start
    } else if (touchStartX < touchEndX - 50) {
      // Swipe right
      setSelectedImageIndex((prevIndex) => (prevIndex - 1 + post.img.split(',').length) % post.img.split(',').length);
      setTouchStartX(null); // Reset touch start
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div id="-single">
      <img src={ads} alt="" />
      <button className="btn-back" onClick={() => navigate(-1)}>ย้อนกลับ</button>
      <div className="content">
        <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div className="detail">
          <div className="item"><PersonIcon /> ผู้โพสต์: {post.username}</div>
          <div className="item"><PhoneIcon /> เบอร์โทรศัพท์: {post.phone}</div>
          <div className="item"><QrCode2Icon /> Line ID: {post.lineid}</div>
        </div>
      </div>
      <div className="user">
        <div className="info">
          <p>ผู้เข้าชม {post.view} ครั้ง⠀</p>
          <p>โพสเมื่อ {moment(post.date).fromNow()}</p>
          {currentUser?.username === post.username && (
            <div className="edit">
              <Link to={`/melbourne/write?edit=2`} state={post}>
                <img src={EditImage} alt="edit" />
              </Link>
              <img onClick={handleDelete} src={DeleteImage} alt="delete" />
            </div>
          )}
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.desc }} />
      </div>
      
      {/* Only show images if there are any */}
      {post.img && post.img.split(',').length > 0 && (
        <div className="img-show" style={{ display: "flex", flexWrap: "wrap" }}>
          {post.img.split(',').map((image, index) => (
            <img
              key={index}
              src={`/upload/${image}`}
              alt={`uploaded-img-${index}`}
              style={{ width: "100px", height: "100px", margin: "10px", cursor: "pointer" }}
              onClick={() => openImageModal(index)} 
            />
          ))}
        </div>
      )}

      {/* Modal สำหรับแสดงภาพขยาย */}
      {isImageModalOpen && (
        <div className="image-modal" style={modalStyle}>
          <span onClick={closeImageModal} style={closeButtonStyle}>&times;</span>
          <div
            className="modal-content"
            style={modalContentStyle}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <img src={`/upload/${post.img.split(',')[selectedImageIndex]}`} alt="expanded" style={expandedImageStyle} />
          </div>
        </div>
      )}
    </div>
  );
};

// สไตล์สำหรับ modal
const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000
};

// สไตล์สำหรับปิดปุ่ม
const closeButtonStyle = {
  position: 'absolute',
  top: '20px',
  right: '30px',
  color: 'white',
  fontSize: '40px',
  cursor: 'pointer'
};

// สไตล์สำหรับภาพขยาย
const expandedImageStyle = {
  maxWidth: '99%',
  maxHeight: '99%',
  objectFit: 'contain'
};

// สไตล์สำหรับเนื้อหาของ modal
const modalContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  maxHeight: '80vh',
  overflowY: 'auto'
};

export default SydneySingle;

import React from 'react'
import Posts from "../images/posts.png"
import Posted from "../images/posted.png"
import Login from "../images/login.png"
import Edit from "../images/editdel.png"

const Howto = () => {
  return (
    <div className='howto'>
    <h1>ขั้นตอนการสร้างโพสต์</h1>
    <h2>1.สมัครสมาชิกและล็อคอินเข้าสู่ระบบก่อน</h2>
    <img src={Login} alt="" />
    <h2>2.จะมีปุ่ม "สร้างโพสต์" ขึ้นมาล่างแถบเมนู</h2>
    <img src={Posted} alt="" />
    <h2>3.เลือกประเภทหัวข้อ และ กรอกข้อมูลต่างๆ</h2>
    <img src={Posts} alt="" />
    <h2>4.เข้าไปที่โพสต์ของเราสามารถแก้ไขโพสต์ หรือ ลบโพสต์ ได้ที่รูป icon สีเขียวและสีแดง</h2>
    <img src={Edit} alt="" />
    </div>
  )
}

export default Howto
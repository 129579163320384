import { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie"; // นำเข้า js-cookie

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    // ดึงข้อมูลผู้ใช้จากคุกกี้
    const user = Cookies.get("userID");
    return user ? JSON.parse(user) : null;
  });

  const [errorMessage, setErrorMessage] = useState("");

  const login = async (inputs) => {
    try {
      const res = await axios.post("https://api.austhaitown.com/api/auth/login", inputs, { withCredentials: true });
      setCurrentUser(res.data); // ตั้งค่าผู้ใช้ปัจจุบัน
      setErrorMessage(""); // ล้างข้อความข้อผิดพลาดเมื่อเข้าสู่ระบบสำเร็จ

      // เก็บข้อมูลผู้ใช้ในคุกกี้
      Cookies.set("userID", JSON.stringify(res.data), { secure: true, sameSite: "None" });
    } catch (error) {
      console.error("Login failed:", error.response ? error.response.data : error.message);
      setErrorMessage(error.response?.data || "An error occurred during login.");
      throw error; // โยนข้อผิดพลาดเพื่อให้ handleSubmit จัดการต่อ
    }
  };

  const logout = async () => {
    try {
      await axios.post("https://api.austhaitown.com/api/auth/logout", {}, { withCredentials: true });
      setCurrentUser(null);
      Cookies.remove("userID"); // ลบข้อมูลผู้ใช้จากคุกกี้
    } catch (error) {
      console.error("Logout failed:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      // เก็บข้อมูลผู้ใช้ในคุกกี้ทุกครั้งที่ currentUser มีการเปลี่ยนแปลง
      Cookies.set("userID", JSON.stringify(currentUser), { secure: true, sameSite: "None" });
    } else {
      Cookies.remove("userID");
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, errorMessage }}>
      {children}
    </AuthContext.Provider>
  );
};
